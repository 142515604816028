import {useEffect, useState} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./UsersLove.module.scss";
import LazyImage from "../../UI/lazyImage/LazyImage";

const UsersLove = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.usersLove;
    const textInfo = strings.plan.usersLove;

    const [visibleImages, setVisibleImages] = useState([1]);
    const [index, setIndex] = useState(2);
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_users_love_appeared');
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (index < 5) {
                setVisibleImages([...visibleImages, index]);
                setIndex(index + 1);
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [index, visibleImages]);

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped');
        nextStep();
    }

    return (
        <div className={mainContainerClass}>
            <h1>{textInfo.title}</h1>
            <div className={`${styles.imagesArea} flex-column`}>
                {visibleImages.map((image, idx) => (
                    <div key={idx} className={`${styles.image} ${ idx % 2 ? styles.imageRight : styles.imageLeft}`}>
                        <LazyImage
                            srcWebp={`/images/plan/usersLove/${themeData.usersLoveFeedbacksFolder}${image}.webp`}
                            src={`/images/plan/usersLove/${themeData.usersLoveFeedbacksFolder}${image}.png`}
                            alt=""
                        />
                    </div>
                ))}
            </div>
            <ContinueButton
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}   

export default UsersLove;