import {useEffect} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import styles from "./AboutResult.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../../UI/lazyImage/LazyImage";
import VideoFeedbacks from "../../UI/videoFeedbacks/VideoFeedbacks";

const AboutResult = props => {
    const { nextStep, data } = props;
    const ageInfo = localStorage.getItem('ageInfo');
    const targetWeight = localStorage.getItem('targetWeight');
    const pageInfo = data.settings.aboutResult
    const themeData = data.themeData

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_section_about_you_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_section_about_you_tapped');
        nextStep();
    }

    const imageClass = `${styles.image} ${pageInfo.smallImage ? styles.smallImage : ''}`
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    return (
        <>
            { pageInfo.decorationImage &&
                <LazyImage
                    className={styles.absoluteImage}
                    srcWebp={strings.images[`${pageInfo.decorationImage}Webp`]}
                    src={strings.images[pageInfo.decorationImage]}
                    alt=""
                    style={pageInfo.decorationImageStyle}
                />
            }
            <div className={mainContainerClass}>
                <ResultHeader
                    url={'/images/result/about-result.jpg'}
                    urlWebp={'/images/result/about-result.webp'}
                    theme={themeData?.menuProgressBackground}
                />
                <div className={`${styles.mainBox} flex-column`}>
                    { pageInfo.video  && strings.getLanguage() === 'en'
                        ? <VideoFeedbacks
                            feedbacks={pageInfo.feedbacks}
                            title={false}
                            name={false}
                            view="circle"
                            themeData={themeData}
                        />
                        : <LazyImage
                            className={imageClass}
                            srcWebp={pageInfo.imageWebp}
                            src={pageInfo.image}
                            alt=""
                        />}
                    <h1>{strings.about.result.title}{ageInfo}</h1>
                    { pageInfo.withMultiColorInfo ?
                        (
                            <div className={`${styles.multiColorText} flex-column`}>
                                <p className={styles.text}>
                                    {strings.about.result.subtitleColor.first}
                                </p>
                                <p className={styles.text}>
                                    {strings.about.result.subtitleColor.second}
                                </p>
                                <p className={`${styles.text} flex-row`}>
                                    {strings.about.result.subtitleColor.start}
                                    <span className={themeData?.aboutResultRedTextClass ? styles[themeData.aboutResultRedTextClass] :
                                        styles.red}>{strings.about.result.subtitleColor.red}</span>
                                    {strings.about.result.subtitleColor.and}
                                    <span className={themeData?.aboutResultBlueTextClass ? styles[themeData.aboutResultBlueTextClass] :
                                        styles.blue}>{strings.about.result.subtitleColor.blue}</span>
                                    {strings.about.result.subtitleColor.end}
                                </p>
                                <p className={styles.text}>
                                    {strings.about.result.subtitleColor.last}
                                </p>
                                <p className={styles.boldText}>
                                    {strings.about.result.subtitleColor.bold}
                                </p>
                            </div>
                        ) : (
                            <p className={`${styles.text} resultSubtitle`}>
                                {strings.about.result.subtitleStart}<b>{targetWeight}</b>,{strings.about.result.subtitleEnd}
                            </p>
                        )
                    }
                </div>
                <ContinueButton nextStep={clickContinue} theme={themeData.continueButton} />
            </div>
        </>
    )
}   

export default AboutResult;